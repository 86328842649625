import React, { useState } from 'react';

//STYLES
import 'assets/css/sign-up-publisher.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPublisherInformation, setStep } from 'redux/become-publisher';
import { makeStyles, TextField } from '@material-ui/core';
import { setMessageUser } from 'utils/helpers';
import { UM } from 'utils/user-messages';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        marginTop: 20
    },
    inputs: {
        width: '100%',
        marginTop: 20
    },
    title: {
        margin: theme.spacing(4, 0, 2)
    },
    lists: {
        paddingLeft: 0
    }
}));

interface IThisProps {
    step: number;
}

export const SignUpPublisherFirstStep = ({ step }: IThisProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const publisherInfo = useSelector(
        (state: IBecomePublisher) => state.BecomePublisher.publisherProfile
    );

    const [nameOfAccount, setNameOfAccount] = useState('');

    function SNameOfAccount(e: any) {
        setNameOfAccount(e.target.value);
    }
    const [accountNumber, setAccountNumber] = useState('');

    function SAccountNumber(e: any) {
        setAccountNumber(e.target.value);
    }
    const [routingNumber, setRoutingNumber] = useState('');

    function SRoutingNumber(e: any) {
        setRoutingNumber(e.target.value);
    }
    const [nameOfBank, setNameOfBank] = useState('');

    function SNameOfBank(e: any) {
        setNameOfBank(e.target.value);
    }

    function nextStep(e: any) {
        e.preventDefault();

        if (
            nameOfAccount === '' ||
            accountNumber === '' ||
            routingNumber === '' ||
            nameOfBank === ''
        ) {
            dispatch(setMessageUser(UM.FILL_ALL));
            return;
        }

        const dataForAccount = {
            ...publisherInfo,
            bankNameAccount: nameOfAccount,
            bankAccountNumber: accountNumber,
            bankRoutingNumber: routingNumber,
            bankName: nameOfBank,
            termsAndConditions: true
        };

        dispatch(setPublisherInformation(dataForAccount));
        dispatch(setStep(2));
    }

    function prevStep() {
        dispatch(setStep(0));
    }

    return (
        <section
            className="form-section"
            style={{ display: step === 1 ? 'block' : 'none' }}>
            <div className="container mb-3">
                <div className="c-red cursor-pointer" onClick={prevStep}>
                    <i className="fas fa-arrow-left mr-2" />
                    Prev
                </div>
            </div>
            <form action="#" className="form-block" onSubmit={nextStep}>
                <h2 className="fs24 font-bold">Bank Details</h2>
                <div className="add-method-box">
                    <TextField
                        className={classes.inputs}
                        id="NameOnAccount"
                        label="Name on Account"
                        onChange={SNameOfAccount}
                        required={true}
                    />

                    <TextField
                        className={classes.inputs}
                        id="AccountNumber"
                        label="Account Number"
                        type="text"
                        onChange={SAccountNumber}
                        required={true}
                    />

                    <TextField
                        className={classes.inputs}
                        id="RoutingNumber"
                        label="Routing Number"
                        onChange={SRoutingNumber}
                        required={true}
                    />

                    <TextField
                        className={classes.inputs}
                        id="NameOfBank"
                        label="Name of Bank"
                        onChange={SNameOfBank}
                        required={true}
                    />
                </div>
                <button type="submit" className="signup-btn mt-5">
                    Continue
                </button>
            </form>
        </section>
    );
};
