export const ALL_URL = {
    HOME: '/',
    EVENT: '/event/:id/:status',
    ALBUM: '/album/:id',
    PUBLISHER: '/publisher',
    MY_DRAFTS: '/my-drafts',
    EDIT_PUBLISHER_INFORMATION: '/edit-information',
    ADMIN_PANEL: '/admin-panel',
    SETTINGS: '/settings',
    MY_DASHBOARD: '/my-dashboard',
    PUBLISHER_PROFILE: '/publisher-profile/:publisherID',
    EDIT_PROFILE: '/edit-profile',
    PROFILE: '/profile',
    VAUCHER_CREATE: '/vaucher-create',
    VAUCHER_MANAGMENT: '/vaucher-managment',
    PRE_ORDER: '/pre-order',
    NO_PREORDER: '/no-preorder',
    EDIT_BOOK: '/edit-book/:bookID',
    BOOK_PREVIEW: '/book-preview',
    FP_EVENT_LIST: '/my-events',
    SEARCH: '/search',
    LIBRARY: '/library/:eventID/:status',
    SERP: '/serp/:eventID',
    SEARCH_EVENTS: '/search-my-events',
    TRANSITION: '/transition',
    COVER_PREVIEW: '/preview-book/:bookID',
    POSTER_PREVIEW: '/view-poster/:posterID',
    PREVIOUS_ORDERS: '/previous-orders',
    BILLING: '/billing',
    FOLDER_VIEW: '/album-view',
    EMBEDDABLE_SETTINGS: '/embeddable-settings',
    ADMIN: '/admin-login',
    ADMIN_BOOK_LIST: '/admin/:tabName',
    SHIPPING: '/shipping',
    SIGN_UP_PUBLISHER: '/sign-up-publisher',
    CREATE_BOOK: '/create/:eventID/:tabName/:eventStatus',
    EDITOR_POSTER_PAGE: '/edit-posters/:eventID/:posterID/:eventStatus',
    MY_LIBRARY: '/my-library',
    SIGN_IN: '/#sign-in',
    SIGN_IN_NO_SLASH: '#sign-in',
    SIGN_UP: '/#sign-up'
};

export const DEF_URL = {
    SEARCH: '/search',
    LIBRARY: '/library',
    EVENT: '/event',
    EDIT_BOOK: '/edit-book',
    ALBUM: '/album',
    SERP: '/serp',
    CREATE_BOOK: '/create',
    EDITOR_POSTER: '/edit-posters',
    COVER_PREVIEW: '/preview-book',
    PUBLISHER_PROFILE: '/publisher-profile',
    MY_EVENTS: '/my-events',
    MY_LIBRARY: '/my-library',
    EDITOR_POSTER_PAGE: '/edit-posters',
    POSTER_PREVIEW: '/view-poster',
    ORDER: 'order',
    ADMIN_BOOK_LIST: '/admin'
};
